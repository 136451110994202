/*
    lets make some assumptions

    There are two possible API responses:
    1. JSON response, has keys:
    - success: boolean (true or false depending on whether request was successful)
    - if response is successful: data: json
    - if response is not successful: reason: text
    2. File/raw response - text or bytes depending on file type

*/

import { IsSlowModeEnabled } from "@/backend/sessionStorage";
import useSWR, { KeyedMutator } from "swr";

interface RequestParams {
    [key: string]: string;
}

export interface BackendResponse {
    success: boolean;
    data: any;
    status: number | null;
    reason: string;
    query: string;
}
export interface BackendResponseLoading {
    isLoading: boolean;
    isError: boolean;
    success: boolean | null;
    data: any;
    status: number | null;
    reason: string | null;
    query: string | null;
    time: number | null;
    // response: BackendResponse | null;
}

function formatResponse(resp: BackendResponse) {
    // return Object.entries(resp).map(
    //     ([key, value]) => `${key}: ${value}`)
    // .join(", ");
    return JSON.stringify(resp);
}

const apiAddress = "/api";

const fetcher = async (request: any) => {
    const [url, isJson, params, options] = request;

    const searchParams =
        params !== null ? "?" + new URLSearchParams(params).toString() : "";

    const response: BackendResponse = {
        success: true,
        data: {},
        status: 0,
        reason: "",
        query: url + searchParams,
    };

    let res;

    // this is mostly for network errors
    try {
        res = await fetch(url + searchParams, {
            ...options
        });
    } catch (error) {
        response.success = false;
        response.reason = "Network error. " + error;

        console.log("API request failed: Network error.", formatResponse(response));

        return response;
    }

    // we got this far - so we have a response
    response.status = res.status;

    if (res.redirected && options.redirect === "follow") {
        window.location.href = res.url;
        return response;
    }

    // if not ok try parsing error
    if (!res.ok) {
        response.success = false;
        response.reason = "error";

        try {
            // ideally the response should be using format 1.
            const decoded = await res.json();

            if (decoded.hasOwnProperty("reason")) {
                response.reason = decoded.reason;
            } else {
                response.reason = "Received invalid response.";
                console.log("Received invalid response:", JSON.stringify(decoded));
            }
        } catch (error) {
            console.error("API request failed: JSON parse error:", error);
            response.reason =
                "Unknown reason - parsing error response as JSON failed.";
        }

        console.error("API request failed:", formatResponse(response));

        return response;
    }

    if (isJson) {
        try {
            const decoded = await res.json();

            // JSON API responses should have data field
            if (decoded.hasOwnProperty("data")) {
                response.data = decoded.data;
            } else {
                response.reason = "Unknown JSON response model: " + decoded;
                response.success = false;

                console.error(
                    "API request failed: Unknown response type:",
                    formatResponse(response)
                );

                return response;
            }
        } catch (error) {
            response.reason = "Parsing JSON response failed.";
            response.success = false;

            console.error(
                "API request failed: JSON parse error",
                formatResponse(response),
                error
            );

            return response;
        }
    } else {
        response.data = await res.text();
    }

    return response;
};

const delayedFetcher = (options: any) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(fetcher(options));
        }, 1000);
});

interface GetRequest {
    endpoint: any;
    params?: RequestParams;
    isJson?: boolean;
    swrOptions?: any;
    condition?: Function | undefined;
}

interface useBackendResponse {
    response: BackendResponse | any;
    isLoading: boolean;
    isValidating: boolean;
    mutate: KeyedMutator<unknown>;
}

export function useBackend(request: GetRequest): useBackendResponse {
    const isJson = typeof(request.isJson) === "undefined" ? true : request.isJson;

    const handler = IsSlowModeEnabled() ? delayedFetcher : fetcher;

    // let condition = true;
    // if (request.condition) {
    //     condition = request.condition();
    // }

    const { data, error, mutate, isLoading, isValidating } = useSWR(
        !request.endpoint ? null : [
            apiAddress + request.endpoint,
            isJson,
            !request.params ? null : request.params,
            {},
        ],
        handler,
        {
            revalidateOnFocus: false,
            refreshWhenHidden: false,
            dedupingInterval: 5000,
            ...request.swrOptions,
        }
    );

    return {
        response: data,
        isLoading,
        isValidating,
        mutate
    };
}

interface SendRequest {
    endpoint: string;
    method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
    params?: RequestParams;
    data?: any;
    json?: any;
    notificationData?: any;
    responseCallback?: Function;
    callbackParams?: any;
    isJson?: boolean;
    followRedirects?: boolean;
}

interface SendRequestLoading extends SendRequest {
    responseCallback: Function;
    previousResponse: BackendResponse;
}

export function backendSend(request: SendRequest) {
    const payload =
        request.json != null ? JSON.stringify(request.json) : request.data;

    const isJson = typeof(request.isJson) === "undefined" ? true : request.isJson;

    const handler = IsSlowModeEnabled() ? delayedFetcher : fetcher;

    // log the time of last request to backend
    sessionStorage.setItem("lastBackendRequest", Math.floor(Date.now() / 1000).toString());

    return handler([
        apiAddress + request.endpoint,
        isJson,
        !request.params ? null : request.params,
        {
            method: !request.method ? "POST" : request.method,
            redirect: !request.followRedirects ? "manual" : "follow",
            body: payload,
            credentials: "include",
        },
    ]).then((response) => {
        if (request.responseCallback != null) {
            request.responseCallback(response, request.callbackParams);
        }
    });
}

export function DefaultBackendResponseLoading(): BackendResponseLoading {
    return {
        isLoading: true,
        isError: false,
        success: null,
        data: null,
        status: null,
        reason: null,
        query: null,
        time: null
    }
}
export function backendSendRefresh(request: SendRequest, currentState: BackendResponseLoading, stateSetter: Function) {
    function OnResponseReceived(response: BackendResponse, params: any) {
        stateSetter({...response, isLoading: false, isError: !response.success, time: Date.now() / 1000});

        // forward to original callback if exists...
        if (request.responseCallback != null) {
            request.responseCallback(response, params);
        }
    }

    // if already loading, do nothing
    if (currentState.isLoading && currentState.success !== null) {
        console.warn(`Tried to execute a request against ${request.endpoint} while one was running...`);
        return;
    }

    stateSetter({...currentState, isLoading: true});
    backendSend({...request, responseCallback: OnResponseReceived});
}

export function httpRequest(request: SendRequest) {
    const payload =
        request.json != null ? JSON.stringify(request.json) : request.data;

    const isJson = typeof(request.isJson) === "undefined" ? true : request.isJson;

    const handler = IsSlowModeEnabled() ? delayedFetcher : fetcher;

    return handler([
        request.endpoint,
        isJson,
        !request.params ? null : request.params,
        {
            method: !request.method ? "POST" : request.method,
            redirect: !request.followRedirects ? "manual" : "follow",
            body: payload
        },
    ]).then((response) => {
        if (request.responseCallback != null) {
            request.responseCallback(response, request.callbackParams);
        }
    });
}
