import "@/styles/globals.css";
import { NextUIProvider, Spinner } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { fontSans, fontMono } from "@/config/fonts";
import type { AppProps } from "next/app";
// import { useBackend } from "@/hooks/useBackend";
import { createContext, useEffect, useState } from "react";
import { CreateModal, DynamicModal, RegisterModal } from "@/components/DynamicModal";
import { SidebarMain } from "@/components/Navigation/Sidebar";
import { useRouter, Router } from "next/router";
import dynamic from "next/dynamic";
import {useTheme} from "next-themes";
import { ToastContainer } from 'react-toastify';
import { BackendResponse, useBackend, backendSend } from "@/hooks/useBackend";

import { RefreshSession } from "@/hooks/SessionContext";
import { FiAlertTriangle } from "react-icons/fi";
import { AreFormChangesPending, SetFormChangesPending } from "@/backend/sessionStorage";
import OutdatedBrowserWarning from "@/components/OutdatedBrowserWarning";
import ErrorBoundary from "@/components/ErrorBoundary";

import NextNProgress from 'nextjs-progressbar';
import { NotificationContextProvider, notificationStorage } from "@/backend/notifications";
// import { AddEventListeners } from "@/backend/bootstrap";
// import { OnNavigationStarted } from "@/backend/FormHandlers";
import BuildCheck from "@/components/BuildCheck";
import CookiesInfoBar from "@/components/CookiesInfoBar";

const localBuild = "c7fe9c3";

export function App({ Component, pageProps }: AppProps) {
	const router = useRouter();

	function NavigateAnyway(params: any) {
		SetFormChangesPending(false);
		
		router.push(params.url);
	};

	useEffect(() => {
		function OnNavigationStarted(url: any, props: any) {
			if (AreFormChangesPending()) {
				// router.events.emit("routeChangeError", "form_changes_pending");
				Router.events.emit("routeChangeError", "form_changes_pending", "form_changes_pending_2", props);
				// console.log(router.events);

				CreateModal({
					title: "Unsaved Changes",
					icon: <FiAlertTriangle />,
					message: "There are unsaved changes. Press confirm to leave the page anyway, or cancel to return.",
					callback: NavigateAnyway,
					callbackParams: { url: url }
				})

				throw "Preventing route change due to unsaved form data. Ignore this error.";
			}
		}

		Router.events.on('routeChangeStart', OnNavigationStarted);
	 
		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			Router.events.off('routeChangeStart', OnNavigationStarted);
		}
	}, [router])

	return (
		<NextUIProvider navigate={router.push}>
			<NextThemesProvider attribute="class" defaultTheme="system">
				<ErrorBoundary>
					<AppContent Component={Component} pageProps={pageProps} />
				</ErrorBoundary>
			</NextThemesProvider>
		</NextUIProvider>
	);
}

function SideFill(props: any) {
	let colorClass;
	if (props.side === "left") {
		if (props.colorScheme === "dark") {
			colorClass = "bg-[#000000]";
		} else {
			colorClass = "bg-[#000a16]";
		}
	} else {
		colorClass = `pagecontainer-${props.colorScheme}`
	}

	return <div className={`hidden md:flex grow min-h-full ${colorClass}`}></div>
}

function AppContent({ Component, pageProps }: any) {
	const router = useRouter(); 

	// const [ sessionData, setSessionData ] = useState<any>({ loggedIn: null });

	const [ isLoggedIn, setLoggedIn ] = useState<boolean | null>(null);
	// const { isLoggedIn } = useSessionContext();
	const { resolvedTheme, setTheme } = useTheme();
	const colorScheme = resolvedTheme === "dark" ? "dark" : "light";

	useEffect(() => {
		RefreshSession(setLoggedIn, false);
	}, [])

	if (isLoggedIn === null) {
		return <></>
	};

	if (isLoggedIn === true) {
		// stuff to do when logged in
		if (router.pathname === "/login" || router.pathname === "/register") {
			setTimeout(router.push, 1000, "/services");

			return <div className="flex justify-center items-center p-6"><Spinner /></div>;
		}
	} else {
		if (router.pathname !== "/login" && router.pathname !== "/reset-password" && router.pathname !== "/register") {
			setTimeout(router.push, 1000, "/login");

			return <div className="flex justify-center items-center p-6"><Spinner /></div>;
		}
	}

	// {
	// 	isLoggedIn ?
	// 		<div className={`${isLoggedIn ? "md:flex" : ""} hidden grow min-h-full bg-[${colorScheme === 'dark' ? '#FF0000' : '#000a16'}]`}></div>
	// 		:
	// 		<div className={`${isLoggedIn ? "md:flex" : ""} hidden grow min-h-full bg-[${colorScheme === 'dark' ? '#FF0000' : '#000a16'}]`}></div>
	// }
	// <div className={`${isLoggedIn ? "md:flex" : ""} grow min-h-full hidden pagecontainer-${colorScheme}`}></div>

	return (
		<>
			<NextNProgress color="#29D" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={false} />
			<OutdatedBrowserWarning />
			<BuildCheck localBuild={localBuild} />
			{
				isLoggedIn ?
					<div className={`flex flex-row justify-center gap-0 min-h-full w-full pagecontainer-${colorScheme}`}>
						<SideFill colorScheme={colorScheme} side="left" />
						<div id="pageContainer" className={"flex flex-grow min-h-full max-w-full pagecontainer-"+colorScheme}>
							<NotificationContextProvider value={notificationStorage.notifications}>
								<SidebarMain shouldDisplay={isLoggedIn} />
							</NotificationContextProvider>
							<div className="w-full md:w-[600px] lg:w-[750px] xl:w-[1000px] 2xl:w-[1100px] 3xl:w-[1200px]">
								<Component {...pageProps} />
							</div>
						</div>
						<SideFill colorScheme={colorScheme} side="right" />
					</div>
					:
					<div className={`gap-0 min-h-full w-full pagecontainer-${colorScheme}`}>
						<Component {...pageProps} />
					</div>
			}
				{/* <div className="w-full md:max-w-[550px] lg:max-w-[800px] xl:max-w-[1024px]"> */}
				{/* <div className="w-full md:w-[calc(100%-192px)] lg:w-[calc(100%-208px)] xl:w-[1000px] 2xl:w-[1050px]"> */}
			<CookiesInfoBar />
			<DynamicModal />
			<ToastContainer theme={colorScheme} />
		</>
	)
	// </UserSessionContext.Provider>
}

export const fonts = {
	sans: fontSans.style.fontFamily,
	mono: fontMono.style.fontFamily,
};

export default dynamic(() => Promise.resolve(App), {
	ssr: false,
});